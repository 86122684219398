<template>
    <bs-popup id="master-vendor-popup" @on-popup-closed="onPopupClosed" :auto-show="true" ref="form">
        <template v-slot:title>
            Master Vendor
        </template>
        <template v-slot:content>
            <bs-error-message :errors="errorMessages" />
            <div class="mb-3">
                <label for="code" class="form-label">Code</label>
                <bs-textbox v-model="model.code" />
                <bs-input-error :errors="errorMessages" keyError="Code" />
            </div>

            <div class="mb-3">
                <label for="code" class="form-label">Name</label>
                <bs-textbox v-model="model.name" />
                <bs-input-error :errors="errorMessages" keyError="name" />
            </div>

        </template>
        <template v-slot:footer>
            <button class="btn btn-sm btn-primary" @click="saveData"><i class="bi bi-check-circle"></i> Save</button>
            <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
        </template>
    </bs-popup>
</template>

<script>
    import MasterVendorService from "@/services/master-vendor-service.js";
    //import LocationCombo from "@/components/LocationCombo";
    // import AirlineCombo from "@/components/AirlineCombo";
    //import CustomerCombo from "@/components/CustomerCombo";

    export default {
        components: {

        },
        data: function () {
            return {
                id: 0,
                model: MasterVendorService.default(),
                errorMessages: null
            }
        },
        mounted() {
            let id = this.$route.params.id;
            if (id !== undefined && id !== null && id !== 0) {
                this.id = id;
                this.loadData();
            }
        },
        methods: {
            async loadData() {
                if (this.id === undefined || this.id === null || this.id === 0) {
                    return;
                }

                const result = await MasterVendorService.getObject(this.id);
                if (result.status) {
                    this.errorMessages = result.errorMessages;
                }
                else {
                    this.errorMessages = null;
                }

                this.model = result.data;
            },
            async onPopupClosed() {
                this.$router.push('/master-vendor');
            },
            async close() {
                this.$refs.form.closePopup();
            },
            async saveData() {
                try {
                    this.$store.commit('progressCounterQueueIncrement');

                    let status = false;
                    if (this.id !== undefined && this.id !== null && this.id !== 0) {
                        const result = await MasterVendorService.update(this.model, this.id);
                        status = result.status;
                        if (status) {
                            this.errorMessages = null;
                        }
                        else {
                            this.errorMessages = result.errorMessages;
                        }
                    }
                    else {
                        const result = await MasterVendorService.create(this.model);
                        status = result.status;
                        console.log('save status', status);
                        if (status) {
                            this.errorMessages = null;
                            this.id = result.data.id;
                        }
                        else {
                            this.errorMessages = result.errorMessages;
                        }
                    }

                    if (!status) return;

                    this.$refs.form.closePopup();
                    this.$router.push(`/master-vendor/detail/${this.id}`);
                }
                finally {
                    this.$store.commit('progressCounterQueueDecrement');
                }
            }
        }
    }
</script>